<template>
  <div>
    <b-card title="เงินคงเหลือ 🚀">
      <b-card-text>
        <balance-box />
      </b-card-text>
      <div>
        <b-row>
          <b-col sm="6" class="pb-2">
            <b-button
              type="button"
              class="btn btn-block"
              variant="primary"
              to="deposit"
            >
              ฝากเงิน
            </b-button>
          </b-col>
          <b-col sm="6">
            <b-button
              type="button"
              class="btn btn-block"
              variant="warning"
              to="withdraw"
            >
              ถอนเงิน
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-button
      variant="gradient-danger"
      @click="playButtonClick"
      class="p-2 btn-block mb-2"
    >
      <feather-icon icon="HeartIcon" class="mr-50 " />
      <span class="align-middle">เข้าเล่นเกมส์</span>
    </b-button>
    <deposit-box v-if="member && member.is_deposit === 0" />
    <b-card title="เมนูลัด 🚀">
      <b-row>
        <b-col v-for="item in menu" :key="item.title" sm="4">
          <b-media no-body class="mb-1" style="cursor:pointer !important">
            <b-media-aside class="mr-2">
              <b-avatar size="40" :variant="item.color">
                <feather-icon size="24" :icon="item.icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <b-button variant="flat-dark" :to="item.routing">
                {{ item.title }}
              </b-button>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BCol,
  BRow,
  BAvatar,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton
} from 'bootstrap-vue'

import BalanceBox from './component/BalanceBox.vue'
import DepositBox from './component/DepositBox.vue'
import { getUserData } from '../auth/utils'

import 'animate.css'

export default {
  components: {
    BCard,
    BCardText,
    BCol,
    BRow,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,
    BalanceBox,
    DepositBox
  },
  data() {
    return {
      user: null,
      member: null,
      menu: [
        {
          title: 'ฝาก',
          icon: 'DollarSignIcon',
          color: 'light-primary',
          routing: 'deposit'
        },
        {
          title: 'ถอน',
          icon: 'SmileIcon',
          color: 'light-warning',
          routing: 'withdraw'
        },
        {
          title: 'โปรโมชั่น',
          icon: 'StarIcon',
          color: 'light-info',
          routing: 'promotion'
        },
        {
          title: 'รับเครดิตเงินคืน',
          icon: 'PackageIcon',
          color: 'light-success',
          routing: 'cashback'
        }
        // {
        //   title: 'รายได้ชวนเพื่อน',
        //   icon: 'UsersIcon',
        //   color: 'light-success'
        // },
        // {
        //   title: 'ตั้งค่า',
        //   icon: 'SettingsIcon',
        //   color: 'light-danger'
        // }
      ]
    }
  },
  methods: {
    async getMemberInfo() {
      const member = await this.$http.get('member/info')

      if (member.status === 200) {
        return member.data
      }
      return null
    },
    loggedOut() {
      if (this.member.id === 25953) {
        console.log('Logout')
        localStorage.removeItem('userData')
        this.$router.push('/')
      }
    },
    playButtonClick() {
      if (this.member.agent_username === null) {
        this.$swal({
          title: 'กำลังสร้างบัญชี',
          text: 'เรียนลูกค้าที่เคารพ กรุณาทำการรีเฟรชหน้าจออีกครั้ง ค่ะ',
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          showClass: {
            popup: 'animate__animated animate__flipInX'
          },
          buttonsStyling: false
        })
      } else {
        console.log('PLAY')
        const routeData = this.$router.resolve({
          name: 'gameplay'
        })
        window.open(routeData.href, '_blank')
      }
    }
  },
  async beforeMount() {
    this.user = getUserData()
    this.member = await this.getMemberInfo()
    this.loggedOut()
  }
}
</script>

<style></style>
